import React, { useEffect, useRef, useState } from 'react';
import {
  setLayoutOptions,
  useFetchingLoader,
  useLoader,
  showAlert,
} from '../../store/app/actions';
import { getUserConfigByCompanyId } from '../../store/companyConfig/actions';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import http from '../../services/http';
import SearchLineWithButton from '../../components/SearchLineWithButton';
import { isTabletOrMobile } from '../../services/mediaQueries';
import {
  Alert,
  Dialog,
  SuccessDailog,
} from '../../components/Dialogs/SwalDialogs';
import Employee from './Employee';
import EmployeeToAdd from './EmployeeToAdd';
import Select from 'react-select';
import useDebounce from '../../hooks/UseDebounce';
import WEIcon from '../../components/WE-IconButton';
import { ActiveButton } from '../../components/WE-Button';
import AddContractorEmployeePopup from './AddContractorEmployee';
import { validateEmployeenew } from '../../services/validators';
import CopyUrlPopup from './copyUrl';
import ConfirmationPopup from './confirmation';
import Tippy from '@tippyjs/react';
import cancel from '../../assets/images/close.svg';
import ViewContractorDetails from './viewContractorDetails';
import more from '../../assets/images/more.svg';
import next from '../../assets/images/next.png';
import adduser from '../../assets/images/add-user.svg';
import search from '../../assets/icons/search.png';
import file from '../../assets/images/file-export.svg';
import moment from 'moment';
import Pagination from 'react-bootstrap/Pagination';
import { getAllUser, usersTotal } from '../../store/user/actions';
import Toast from 'react-bootstrap/Toast';
import {
  Modal,
  Box,
  Grid,
  styled,
  Typography,
  Paper,
  Tooltip,
  Button,
} from '@material-ui/core';
import Excel from 'exceljs';

const initialiState = {
  userId: null,
  fName: '',
  lName: '',
  phone: '',
  email: '',
  password: '',
  role: 6,
  kitchensIds: [],
  contractors: [],
  employeeId: '',
  card: '',
  status: 'created',
  contractorsIds: [],
};

const ContractorEmployee = ({
  user,
  kitchen,
  setLayoutOptions,
  UserStatuses,
  Kitchens,
  Roles,
  useLoader,
  useFetchingLoader,
  getAllUser,
  usersTotal,
  allUsers,
  companyName,
  cardNumberLengthForUser,
  employeeNumberLengthForUser,
  getUserConfigByCompanyId,
  companyId,
  isStrippedCard,
  cardGenerationUrlForUser,
  cibusEnabled,
  fieldNamesSettings,
}) => {
  const { t, i18n } = useTranslation();
  const [kitchensIds, setKitchensIds] = useState([]);
  const [usersToDisplay, setUsersToDisplay] = useState(null);
  const [employeeData, setEmployeeData] = useState([]);
  const [payload, setPayload] = useState(initialiState);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [open, setOpen] = useState(false);
  const [gridApi, setGridApi] = useState();
  const [columnApi, setColumnApi] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [update, setUpdate] = useState();
  const [isUpdate, setIsUpdate] = useState(false);
  const [prefetchData, setPrefetchData] = useState(null);
  const [dummy, setDummy] = useState();
  const [copyData, setCopyData] = useState();
  const [resendData, setResendData] = useState();
  const [copied, setCopied] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [contractorList, setContractorList] = useState();
  const [errors, setErrors] = useState({});

  const [tableColumn, setTableColumn] = useState();
  const [viewDetails, setViewDetails] = useState(false);
  const [details, setDetails] = useState();
  const [newPageSizeUsers, setPageSizeUsers] = useState(25); // Records per page
  const [totalUserRecords, setTotalUserRecords] = useState(0); // Total records
  const [pageCountsUsers, setpageCountsUsers] = useState(1); // Total pages count
  const [currentPageUsers, setCurrentPageUsers] = useState(1); // Current page
  const [searchKeywordUsers, setSearchKeywordUsers] = useState(''); // Search text
  const [userStatusId, setUserStatusId] = useState(null);
  const [userContractorId, setUserContractorId] = useState(null);
  const [userKitchenId, setUserKitchenId] = useState(null);
  const [forceResetPassword, setForceResetPassword] = useState(false);
  const [columnAndOrder, setColumnAndOrder] = useState({});
  const [disableButton, setDisableButton] = useState(false);
  const [openDownload, setOpenDownload] = useState(false);
  const [currentUserKitchens, setCurrentUserKitchesList] = useState([]);
  const [userStatus, setUserStatus] = useState(5);
  const [successCreateModal, setSuccessCreateModal] = useState(false);
  const [successCreateModalIsEdit, setSuccessCreateModalIsEdit] =
    useState(false);
  const [successCreateModalPayload, setSuccessCreateModalPayload] =
    useState(initialiState);
  const [copyToClipMess, setCopyToClipMess] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const fetch = async () => {
      getUserConfigByCompanyId(companyId);
    };
    fetch();
  }, []);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '1px solid #ededed',
    boxShadow: 24,
    padding: '15px',
    p: 4,
    minWidth: '350px',
  };
  const handleOpen = (value) => {
    if (!value) {
      setIsUpdate(true);
    } else {
      if (value === 'recreate') {
        setIsUpdate(false);
      } else {
        setPayload(initialiState);
        setIsUpdate(false);
      }
    }
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setKitchensIds([]);
    setErrors({});
  };
  const Limit = 2;
  const UserStatusesMap_Status = UserStatuses.reduce((acc, status) => {
    return { ...acc, [status.value]: status };
  }, {});
  const UserStatusesMap_Id = UserStatuses.reduce((acc, status) => {
    return { ...acc, [status.id]: status };
  }, {});
  const RolesMap = Roles.reduce((acc, role) => {
    return { ...acc, [role.value]: role.id };
  }, {});
  const UserStatusesMap = UserStatuses.reduce((acc, status) => {
    return { ...acc, [status.value]: status.id };
  }, {});

  useEffect(() => {
    const fetchData1 = async () => {
      let userKitchen = Kitchens.filter((el) =>
        user?.KitchenId?.includes(el.id)
      );
      setCurrentUserKitchesList(userKitchen);
    };
    fetchData1();
  }, []);

  const handleReset = () => {
    setColumnAndOrder({});
    setSearchKeywordUsers('');
    setUserStatusId('null');
    setUserContractorId('null');
    setUserKitchenId('null');
    gridApi.setSortModel([]);
  };

  const getLimitUserData = async () => {
    let filter = {
      companyId: user.companyId,
      limit: newPageSizeUsers,
      page: currentPageUsers,
      statusId: userStatusId,
      contractorId: userContractorId,
      kitchenId: userKitchenId,
      orderField:
        'field' in columnAndOrder ? columnAndOrder?.field : 'createdAt',
      orderTarget: 'order' in columnAndOrder ? columnAndOrder?.order : 'desc',
      search: searchKeywordUsers ? searchKeywordUsers : undefined,
    };
    await useFetchingLoader(async () => await getAllUser(filter));
  };

  useEffect(() => {
    const fetchData1 = async () => {
      await getLimitUserData();
    };
    fetchData1();
  }, [
    dummy,
    kitchen,
    newPageSizeUsers,
    currentPageUsers,
    columnAndOrder,
    userKitchenId,
    userContractorId,
    userStatusId,
  ]);
  const handleChangeKitchenFilter = async (userTypeId) => {
    setCurrentPageUsers(1);
    setUserKitchenId(userTypeId);
  };
  const handleChangeContractorFilter = async (userTypeId) => {
    setCurrentPageUsers(1);
    setUserContractorId(userTypeId);
  };
  const handleChangeUserStatusFilter = async (userTypeId) => {
    setCurrentPageUsers(1);
    setUserStatusId(userTypeId);
  };
  useEffect(() => {
    const handleResize = (event) => {
      setWidth(event.target.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    const fetchData1 = async () => {
      const contractorData = await http.get(`contractors/getAllContractors`);
      setContractorList(contractorData.data);
      // let filter = {
      //   roleId: [RolesMap.contractorEmployee],
      //   companyId: user.companyId,
      //   contractorId: user.contractorId,
      //   // userStatusId: { ne: UserStatusesMap.deleted },
      // };
      // let filter = {
      //   roleId: [
      //     RolesMap.contractorEmployee
      //   ],
      //   companyId: user.companyId,
      //   limit: newPageSizeUsers,
      //   page: currentPageUsers,
      //   keyword: searchKeywordUsers,
      // };
      // const { data, total } = await useFetchingLoader(
      //   async () => await http.get(`users`, { filter })
      // );
      setPrefetchData(allUsers);
      let serverUsers = allUsers.map((u, i) => ({
        userId: u.id,
        'Employee name': [u.firstName, u.lastName].join(' '),
        firstName: u.firstName,
        lastName: u.lastName,
        kitchensIds: Kitchens?.filter((obj) =>
          u?.kitchens.some((el) => el.id === obj.id)
        ).map((obj) => obj.id),
        employeeId: u?.employeeId ? u?.employeeId : '--',
        cardId: u?.cardId ? u?.cardId : '--',
        Status: UserStatuses.find((el) => el.id === u.userStatusId).display,
        createdAt: u.createdAt
          ? u.createdAt.split('T')[0]
          : undefined /* Format : YYYY-MM-DD */,
        Kitchens: Kitchens?.filter(
          (obj) => u?.kitchens.find((el) => el.id === obj.id) !== undefined
        )
          .map((obj) => obj.name)
          ?.join(','),
        Contractors: u?.contractors ? u?.contractors : '--',
        email: u?.email ? u?.email : '--',
        Action: i,
        phone: u?.phone ? u?.phone : '--',
        creditCard: u.creditCard,
        magneticStripe: u?.magneticStripe,
      }));
      setTotalUserRecords(usersTotal);
      setUsersToDisplay(serverUsers);
      setpageCountsUsers(Math.ceil(usersTotal / newPageSizeUsers));
    };
    fetchData1();
  }, [dummy, kitchen, allUsers]);

  const handleViewMore = (data) => {
    setViewDetails(!viewDetails);
    setDetails(data);
  };
  useEffect(() => {
    const columnDefs = [
      {
        field: 'firstName',
        headerName: t('employeeName'),
        sortable: true,
        comparator: () => {},
        suppressMovable: true,
        //pinned: 'left',
      },
      {
        field: 'employeeId',
        headerName: fieldNamesSettings?.find((el) => el.value === 'employeeId')
          ? fieldNamesSettings
              ?.find((el) => el.value === 'employeeId')
              ?.translations.find(
                (el) => el.language.shortCode == i18n.language
              )?.text
          : t('employeeNo'),
        sortable: true,
        comparator: () => {},
        suppressMovable: true,
      },
      {
        field: 'cardId',
        headerName: fieldNamesSettings?.find((el) => el.value === 'cardId')
          ? fieldNamesSettings
              ?.find((el) => el.value === 'cardId')
              ?.translations.find(
                (el) => el.language.shortCode == i18n.language
              )?.text
          : t('cardNo'),
        sortable: true,
        comparator: () => {},
        suppressMovable: true,
      },
      {
        field: 'Status',
        headerName: t('status'),
        suppressMovable: true,
      },
      {
        field: 'createdAt',
        headerName: t('registrationDate'),
        sortable: true,
        comparator: () => {},
        suppressMovable: true,
      },
      {
        field: 'Kitchens',
        headerName: t('kitchenname'),
        suppressMovable: true,
        resizable: true,
        cellRenderer: 'KitcheCellRender',
      },
      {
        field: 'email',
        headerName: t('email'),
        sortable: true,
        comparator: () => {},
        suppressMovable: true,
      },
      {
        field: 'Contractors',
        headerName: t('contractors'),
        cellRenderer: 'ContractorCellRender',
        suppressMovable: true,
      },
      {
        field: 'phone',
        headerName: t('phone'),
        sortable: true,
        comparator: () => {},
        suppressMovable: true,
      },
      {
        field: '',
        headerName: '',
        cellRenderer: 'ViewMore',
        headerClass: 'resizable-header',
        suppressMovable: true,
        width: 100,
      },
    ];

    // Tab view
    const columnDefsTab = [
      {
        field: 'firstName',
        headerName: t('employeeName'),
        sortable: true,
        comparator: () => {},
        suppressMovable: true,
        //pinned: 'left',
      },
      {
        field: 'employeeId',
        headerName: fieldNamesSettings?.find((el) => el.value === 'employeeId')
          ? fieldNamesSettings
              ?.find((el) => el.value === 'employeeId')
              ?.translations.find(
                (el) => el.language.shortCode == i18n.language
              )?.text
          : t('employeeNo'),
        sortable: true,
        comparator: () => {},
        suppressMovable: true,
      },
      {
        field: 'cardId',
        headerName: fieldNamesSettings?.find((el) => el.value === 'cardId')
          ? fieldNamesSettings
              ?.find((el) => el.value === 'cardId')
              ?.translations.find(
                (el) => el.language.shortCode == i18n.language
              )?.text
          : t('cardNo'),
        sortable: true,
        comparator: () => {},
        suppressMovable: true,
      },
      {
        field: 'Status',
        headerName: t('status'),
        sortable: true,
        suppressMovable: true,
      },
      {
        field: 'createdAt',
        headerName: t('registrationDate'),
        sortable: true,
        comparator: () => {},
        suppressMovable: true,
        hide: true,
      },
      {
        field: 'Kitchens',
        headerName: t('kitchenname'),
        suppressMovable: true,
        resizable: true,
        cellRenderer: 'KitcheCellRender',
        hide: true,
      },
      {
        field: 'Contractors',
        headerName: t('contractors'),
        cellRenderer: 'ContractorCellRender',
        sortable: true,
        suppressMovable: true,
      },
      {
        field: 'email',
        headerName: t('email'),
        sortable: true,
        comparator: () => {},
        suppressMovable: true,
        hide: true,
      },
      {
        field: 'phone',
        headerName: t('phone'),
        sortable: true,
        comparator: () => {},
        suppressMovable: true,
        hide: true,
      },
      {
        field: '',
        headerName: '',
        cellRenderer: 'ViewMore',
        headerClass: 'resizable-header',
        suppressMovable: true,
        width: 100,
      },
    ];

    /**** mobile view column */
    const columnDefsMob = [
      {
        field: 'firstName',
        headerName: t('employeeName'),
        sortable: true,
        comparator: () => {},
        suppressMovable: true,
        //pinned: 'left',
      },
      {
        field: 'employeeId',
        headerName: t('employeeNo'),
        sortable: true,
        comparator: () => {},
        suppressMovable: true,
      },
      {
        field: 'cardId',
        headerName: t('cardNo'),
        sortable: true,
        comparator: () => {},
        suppressMovable: true,
      },
      {
        field: 'createdAt',
        headerName: t('registrationDate'),
        sortable: true,
        comparator: () => {},
        suppressMovable: true,
        hide: true,
      },
      {
        field: 'Kitchens',
        headerName: t('kitchenname'),
        suppressMovable: true,
        resizable: true,
        cellRenderer: 'KitcheCellRender',
        hide: true,
      },
      {
        field: 'email',
        headerName: t('email'),
        sortable: true,
        comparator: () => {},
        suppressMovable: true,
        hide: true,
      },
      {
        field: 'phone',
        headerName: t('phone'),
        sortable: true,
        comparator: () => {},
        suppressMovable: true,
        hide: true,
      },
      {
        field: 'Contractors',
        headerName: t('contractors'),
        sortable: true,
        suppressMovable: true,
        cellRenderer: 'ContractorCellRender',
      },
      {
        field: '',
        headerName: '',
        cellRenderer: 'ViewMore',
        headerClass: 'resizable-header',
        suppressMovable: true,
        width: 100,
      },
    ];
    if (window.innerWidth <= 1024 && window.innerWidth >= 479) {
      setTableColumn(columnDefsTab);
    } else if (window.innerWidth <= 480) {
      setTableColumn(columnDefsMob);
    } else {
      setTableColumn(columnDefs);
    }
  }, []);

  // ag grid data

  const defaultColDef = {
    sortable: true,
    resizable: true,
    comparator: () => {},
    suppressMovable: true,
  };

  const gridOptions = {
    rowHeight: 80,
    groupHeaderHeight: 60,
    headerHeight: 60,
    rowStyle: {
      'border-bottom': 'white 10px solid',
      'border-top': 'white 0px solid',
      'border-radius': '5px',
      padding: '0px 5px',
    },
  };
  const getUserData = () => {
    getLimitUserData();
  };
  const onFilterTextBoxChanged = (e) => {
    setCurrentPageUsers(1);
    const value = e.target.value;
    setSearchKeywordUsers(value);
  };
  /******* add New employee */
  const handleAddNewUser = async (userData) => {
    // setDummy(false);
    userData.password =
      userData.password?.length > 0 ? userData.password : null;
    userData.email = userData.email?.length > 0 ? userData.email : null;
    userData.kitchensIds = userData.kitchensIds || [];
    try {
      //  let userToAdd = userData;
      let userToAdd;
      userData.employeeId =
        userData.employeeId && userData.employeeId !== ''
          ? userData.employeeId
          : null;
      userData.cardId =
        userData.cardId && userData.cardId !== '' ? userData.cardId : null;
      await useFetchingLoader(async () => {
        userToAdd = await http.post(`users/create`, {
          ...userData,
          companyId: user.companyId,
        });
      });
      // handleClose();
      // setToggleAddUser(false);
      if (userToAdd?.errorMessage === 'passwordPolicy') {
        setTimeout(() => {
          Dialog({
            text:
              t('character') +
              ' ' +
              userToAdd?.errorLetter +
              ' ' +
              t('isNotVaild') +
              ' ' +
              t('passwordSecurity'),
            type: 'error',
            buttons: {
              showCancelButton: false,
              confirmButtonText: t('passwordUpdatedConfirmButtonText'),
            },
          });
        }, 150);
      } else {
        setDummy(!dummy);
        setSuccessCreateModalPayload(userToAdd);
        setSuccessCreateModal(true);
        setSuccessCreateModalIsEdit(false);
      }
      handleClose();
    } catch (err) {
      setTimeout(() => Alert({ error: err }), 250);
      return err;
    }
  };
  const handleCloseSuccessCreateModal = () => {
    setSuccessCreateModal(false);
    setSuccessCreateModalIsEdit(false);
    setSuccessCreateModalPayload({});
    setPayload(initialiState);
  };
  /******** update employee details */
  const handleUpdateUser = async (event) => {
    event.preventDefault();
    try {
      // setDummy(false);
      let details = {
        firstName: payload.fName,
        lastName: payload.lName,
        email: payload.email,
        phone: payload.phone,
        // userStatusId:prefetchData?.find(el => el.id === payload.userId)?.userStatusId,
        userStatusId: UserStatuses.find((el) => el.display === payload.status)
          .id,
        kitchensIds: kitchensIds,
        password: payload.password === '' ? undefined : payload.password,
        contractorsIds:
          payload.contractorsIds && payload.contractorsIds.length !== 0
            ? payload.contractorsIds
            : payload.contractors && payload.contractors.length !== 0
              ? payload.contractors.map((el) => el.id)
              : null,
        contractors: payload.contractors,
        cardId:
          !payload.card || payload.card === '' || payload.card === '--'
            ? null
            : isStrippedCard
              ? payload.card.replace(/^0+/, '')
              : payload.card,
        employeeId:
          !payload.employeeId ||
          payload.employeeId === '' ||
          payload.employeeId === '--'
            ? null
            : payload.employeeId,
        roleId: RolesMap.contracorEmployee,
        contractorId: user.contractorId,
        magneticStripe: payload.magneticStripe,
        // value: payload.value,
      };
      const { isValid, errors } = validateEmployeenew(
        details,
        cardNumberLengthForUser,
        employeeNumberLengthForUser
      );
      setErrors({ ...errors });
      if (!isValid) {
        return;
      }
      const userId = payload.userId;
      const response = await useFetchingLoader(
        async () =>
          await http.put(`users/${userId}`, {
            ...details,
            phone: details.phone !== '' ? details.phone : details.phone,
          })
      );
      setDummy(!dummy);
      setViewDetails(false);
      handleClose();
      setSuccessCreateModalPayload(response);
      setSuccessCreateModal(true);
      setSuccessCreateModalIsEdit(true);
      setKitchensIds([]);
    } catch (err) {
      setTimeout(() => Alert({ error: err }), 250);
      return err;
    }
  };

  const onPageSizeChanged = (newPageSize) => {
    var value = document.getElementById('page-size').value;
    gridApi.paginationSetPageSize(Number(value));
  };

  const onBtnExport = async (fileName) => {
    try {
      if (disableButton || tableColumn.length === 0) {
        console.log('Disabled or empty table');
      } else {
        setDisableButton(true);
        setOpenDownload(true);
        const arr = [
          'firstNamePH',
          'lastNamePH',
          'email',
          'user_type',
          'status',
          fieldNamesSettings?.find((el) => el.value === 'cardId')
            ? fieldNamesSettings
                ?.find((el) => el.value === 'cardId')
                ?.translations.find(
                  (el) => el.language.shortCode == i18n.language
                )?.text
            : 'cardIdPH',
          fieldNamesSettings?.find((el) => el.value === 'employeeId')
            ? fieldNamesSettings
                ?.find((el) => el.value === 'employeeId')
                ?.translations.find(
                  (el) => el.language.shortCode == i18n.language
                )?.text
            : 'employeeNo',
          'kitchens',
          'phonePH',
          'contractorName',
          'paymentMethod',
          'updatedOn',
          'startDate',
          cibusEnabled
            ? fieldNamesSettings?.find((el) => el.value === 'magneticStripe')
              ? fieldNamesSettings
                  ?.find((el) => el.value === 'magneticStripe')
                  ?.translations.find(
                    (el) => el.language.shortCode == i18n.language
                  )?.text
              : 'magneticStripe'
            : undefined,
          'guestLink',
          'registrationDate',
          'endDate',
        ].filter((el) => el !== undefined);
        const resArr = arr.map((el) => t(el));
        const filter = {
          companyId: localStorage.getItem('companyId'),
          limit: totalUserRecords,
          page: 1,
          keyword: searchKeywordUsers,
          statusId: userStatusId,
          contractorId: userContractorId,
          kitchenId: userKitchenId,
          orderField:
            'field' in columnAndOrder ? columnAndOrder?.field : 'firstName',
          orderTarget:
            'order' in columnAndOrder ? columnAndOrder?.order : 'desc',
          search: searchKeywordUsers ? searchKeywordUsers : undefined,
        };
        for (const key in filter) {
          if (
            filter[key] === undefined ||
            filter[key] === null ||
            filter[key] === 'null'
          ) {
            delete filter[key];
          }
        }
        const res = await http.post(
          `users/download`,
          {
            isReport: true,
            headers: resArr,
          },
          { ...filter }
        );
        const workbook = new Excel.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');
        worksheet.addRows(res.data);
        const uint8Array = await workbook.xlsx.writeBuffer();
        const blob = new Blob([uint8Array], {
          type: 'application/octet-binary',
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${fileName}.xlsx`;
        a.click();
        a.remove();
        setDisableButton(false);
        setOpenDownload(false);
      }
    } catch (e) {
      setDisableButton(false);
      setOpenDownload(false);
    }
  };

  const ResendVerification = async (el) => {
    const res = await useFetchingLoader(
      async () =>
        await http.post(`users/resetPassword`, {
          userData: { email: el.Email, resendLink: true },
          companyId: localStorage.getItem('companyId'),
        })
    );
    if (res?.sent) {
      Dialog({
        title: t('resendMessage'),
        type: 'success',
        buttons: {
          showCancelButton: false,
          confirmButtonText: t('confirm'),
        },
        onConfirm: () => {
          //setDummy(true);
        },
      });
    }
  };

  const handleDeleteUser = async () => {
    try {
      let details = {
        userStatusId: 5,
      };
      const response = await http.put(`users/${deleteId}`, { ...details });

      if (response) {
        setConfirmation(false);
        setTimeout(() => {
          Dialog({
            title: t('deleteMessage'),
            type: 'success',
            buttons: {
              showCancelButton: false,
              confirmButtonText: t('confirm'),
            },
            onConfirm: () => {
              setViewDetails(false);
              setDummy((prev) => !prev);
            },
          });
        }, 100);
      }
      // setDummy(false);
      setDeleteId('');
    } catch (err) {
      setConfirmation(false);
      setTimeout(() => {
        Alert({ error: err });
      }, 100);
    }
  };

  const CopyDataUrl = async (el) => {
    const res = await useFetchingLoader(
      async () =>
        await http.post(`users/copyUrl`, {
          userData: { email: el.Email },
        })
    );
    setResendData(res);

    setCopyData(true);
  };
  const handleCloseUrl = () => {
    setCopyData(false);
    setCopied(false);
  };
  const handleConfirmation = (userId, status = 5) => {
    setUserStatus(status);
    setConfirmation(!confirmation);
    setDeleteId(userId);
  };
  const printSortStateToConsole = () => {
    const sortState = gridApi.getSortModel();
    if (sortState.length === 0) {
      setColumnAndOrder({});
    } else {
      setColumnAndOrder({
        field: sortState[0].colId,
        order: sortState[0].sort,
      });
    }
  };
  const lang = localStorage.getItem('language');
  return (
    <div className="user-management-table global-data-table">
      <div className="container">
        <div className="add-employee-btn">
          <div className="search-user-wrapper">
            <div className="search-block">
              <div className="search-input">
                <img src={search} alt="search" />
                <input
                  type="text"
                  id="filter-text-box"
                  placeholder={`${t('freeTextSearch')}`}
                  onInput={onFilterTextBoxChanged}
                  value={searchKeywordUsers}
                />
              </div>
              <button onClick={getUserData} className="search-button">
                <img src={search} alt="search" />
              </button>
              <button
                onClick={() => handleReset()}
                className="reset-button-user-management"
              >
                <img src={cancel} alt="reset" />
              </button>
            </div>
            <div className="filter-block filter-block-flex">
              <div className="from-date filter-elem-max-width">
                <select
                  name="userType"
                  placeholder={t('filter')}
                  onChange={(event) =>
                    handleChangeUserStatusFilter(event.target.value)
                  }
                  value={userStatusId}
                >
                  <option value="null">{t('selectUserStatus')}</option>
                  <option value="1">{t('created')}</option>
                  <option value="2">{t('pending')}</option>
                  <option value="3">{t('active')}</option>
                  <option value="4">{t('blocked')}</option>
                  <option value="5">{t('deleted')}</option>
                </select>
              </div>
              <div className="from-date filter-elem-max-width">
                <select
                  name="userType"
                  placeholder={t('filter')}
                  onChange={(event) =>
                    handleChangeContractorFilter(event.target.value)
                  }
                  value={userContractorId}
                >
                  <option value="null">{t('selectContractor')}</option>
                  {user?.contractors
                    ? user?.contractors.map((el) => (
                        <option value={el.id}>{el.name}</option>
                      ))
                    : []}
                </select>
              </div>
              <div className="from-date filter-elem-max-width">
                <select
                  name="userType"
                  placeholder={t('filter')}
                  onChange={(event) =>
                    handleChangeKitchenFilter(event.target.value)
                  }
                  value={userKitchenId}
                >
                  <option value="null">{t('selectKitchen')}</option>
                  {Kitchens
                    ? Kitchens.filter((elem) =>
                        user.KitchenId.includes(elem.id)
                      ).map((el) => <option value={el.id}>{el.name}</option>)
                    : []}
                </select>
              </div>
            </div>
          </div>
          <div className="active-button">
            <div className="total-data">
              <p>
                {t('totalData')}: <span>{totalUserRecords}</span>
              </p>
            </div>
            <div className="actions">
              <img
                onClick={() =>
                  onBtnExport(
                    `${companyName}-users-${moment().format('DD-MMM-YYYY')}`
                  )
                }
                src={file}
                alt="add"
              />
              <img src={adduser} alt="add" onClick={() => handleOpen('add')} />
            </div>
          </div>
        </div>
        <div
          className="ag-theme-alpine"
          style={{ height: '100%', width: '100%' }}
        >
          <AgGridReact
            className="ag-theme-alpine"
            rowData={usersToDisplay}
            columnDefs={tableColumn}
            reactUi={lang === '"he"' ? false : true}
            enableRtl={lang === '"he"' ? true : false}
            onSortChanged={() => printSortStateToConsole()}
            frameworkComponents={{
              PopupCellRenderer: (e) => (
                <PopupCellRenderer
                  handleOpen={handleOpen}
                  e={e}
                  setUpdate={setUpdate}
                  setPayload={setPayload}
                  setIsEdit={setIsEdit}
                  t={t}
                  kitchensIds={kitchensIds}
                  setKitchensIds={setKitchensIds}
                  Kitchens={Kitchens}
                  ResendVerification={ResendVerification}
                  handleDeleteUser={handleDeleteUser}
                  CopyDataUrl={CopyDataUrl}
                  handleConfirmation={handleConfirmation}
                  UserStatuses={UserStatuses}
                  contractorList={contractorList}
                  handleViewMore={handleViewMore}
                />
              ),
              ViewMore: (e) => (
                <ViewMore
                  e={e}
                  allContractors={user?.contractors}
                  handleViewMore={handleViewMore}
                />
              ),
              CreditCard: (e) => <CreditCard e={e} />,
              KitcheCellRender: (e) => <KitcheCellRender e={e} />,
              ContractorCellRender: (e) => <ContractorCellRender e={e} />,
            }}
            domLayout={'autoHeight'}
            gridOptions={gridOptions}
            rowSelection="multiple"
            onGridReady={(params) => {
              setGridApi(params.api);
              setColumnApi(params.columnApi);
              params.api.sizeColumnsToFit();
              document.querySelector('.ag-pinned-left-cols-container').remove();
            }}
            debug={false}
            overlayLoadingTemplate={'<p></p>'}
            rowGroupPanelShow={'always'}
            pivotPanelShow={'always'}
            enableRangeSelection={true}
            pagination={false}
            paginationPageSize={50}
            paginationNumberFormatter={function (params) {
              return '[' + params.value.toLocaleString() + ']';
            }}
          ></AgGridReact>
          <div className="pagination-view we-pagination-view">
            <div className="we_show_records_main">
              <p>
                {newPageSizeUsers * (currentPageUsers - 1) + 1} to{' '}
                {newPageSizeUsers * currentPageUsers} of {totalUserRecords}
              </p>
            </div>
            <Pagination>
              <Pagination.First
                disabled={pageCountsUsers <= 1 ? true : false}
                onClick={() => {
                  setCurrentPageUsers(1);
                }}
              />
              <Pagination.Prev
                disabled={pageCountsUsers <= 1 ? true : false}
                onClick={() => {
                  currentPageUsers - 1 > 0
                    ? setCurrentPageUsers(currentPageUsers - 1)
                    : '';
                }}
              />

              <Pagination.Item active>
                Page {currentPageUsers} of{' '}
                {Math.ceil(usersTotal / newPageSizeUsers)}
              </Pagination.Item>

              <Pagination.Next
                disabled={pageCountsUsers <= 1 ? true : false}
                onClick={() => {
                  currentPageUsers < Math.ceil(usersTotal / newPageSizeUsers)
                    ? setCurrentPageUsers(currentPageUsers + 1)
                    : '';
                }}
              />
              <Pagination.Last
                disabled={pageCountsUsers <= 1 ? true : false}
                onClick={() => {
                  setCurrentPageUsers(Math.ceil(usersTotal / newPageSizeUsers));
                }}
              />
            </Pagination>
          </div>
        </div>
        {/* <div className="pagination-header">
        {t('pageSize')}:
        <select onChange={() => onPageSizeChanged()} id="page-size">
          <option value="10" selected={true}>
            10
          </option>
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div> */}
        <AddContractorEmployeePopup
          fieldNamesSettings={fieldNamesSettings}
          open={open}
          handleClose={handleClose}
          Kitchens={Kitchens}
          Roles={Roles}
          UserStatuses={UserStatuses}
          // userData={{ name: '' }}
          userData={prefetchData}
          newUserFlag={true}
          handleAddNewUser={handleAddNewUser}
          isEdit={true}
          payload={payload}
          setPayload={setPayload}
          handleUpdateUser={handleUpdateUser}
          setIsEdit={setIsEdit}
          isUpdate={isUpdate}
          kitchensIds={kitchensIds}
          setKitchensIds={setKitchensIds}
          currentUserKitchens={currentUserKitchens}
          t={t}
          i18n={i18n}
          errors={errors}
          setErrors={setErrors}
          user={user}
          contractorList={contractorList}
          cardNumberLengthForUser={cardNumberLengthForUser}
          employeeNumberLengthForUser={employeeNumberLengthForUser}
          isStrippedCard={isStrippedCard}
          cardGenerationUrlForUser={cardGenerationUrlForUser}
          cibusEnabled={cibusEnabled}
        />
        <CopyUrlPopup
          open={copyData}
          handleClose={CopyDataUrl}
          resendData={resendData}
          handleCloseUrl={handleCloseUrl}
          copied={copied}
          setCopied={setCopied}
        />
        <ConfirmationPopup
          open={confirmation}
          handleClose={() => setConfirmation(false)}
          handleDeleteUser={handleDeleteUser}
          userStatus={userStatus}
        />
        <ViewContractorDetails
          fieldNamesSettings={fieldNamesSettings}
          open={viewDetails}
          details={details}
          handleClose={handleViewMore}
          CopyDataUrl={CopyDataUrl}
          ResendVerification={ResendVerification}
          handleConfirmation={handleConfirmation}
          handleOpen={handleOpen}
          setPayload={setPayload}
          UserStatuses={UserStatuses}
          setKitchensIds={setKitchensIds}
          Kitchens={Kitchens}
          Roles={Roles}
          cibusEnabled={cibusEnabled}
          contractorList={contractorList}
          //setMealTypesIds={setMealTypesIds}
        />
        <Toast
          onClose={() => setOpenDownload(false)}
          show={openDownload}
          style={{
            position: 'sticky',
            right: '20px',
            bottom: '70px',
          }}
        >
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
              onClick={() => setOpenDownload(false)}
            />
          </Toast.Header>
          <Toast.Body>{t('downloadFile')}</Toast.Body>
        </Toast>

        <Modal
          open={successCreateModal}
          onClose={() => handleCloseSuccessCreateModal()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="close-btn">
              <img
                src={cancel}
                alt="close"
                onClick={() => handleCloseSuccessCreateModal()}
              />
            </div>
            <Typography id="modal-modal-description">
              <div className="user-create-success-wrapper">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="107"
                  height="104"
                  viewBox="0 0 107 104"
                  fill="none"
                >
                  <path
                    d="M27 88.5L13.25 85.5001C12 85.2501 11 84.6034 10.25 83.5601C9.5 82.5201 9.20833 81.3751 9.375 80.1251L10.75 66.0001L1.375 55.2501C0.541667 54.3334 0.125 53.2501 0.125 52.0001C0.125 50.7501 0.541667 49.6667 1.375 48.7501L10.75 38.0001L9.375 23.8751C9.20833 22.6251 9.5 21.4801 10.25 20.4401C11 19.3967 12 18.7501 13.25 18.5001L27 15.5001L34.25 3.25005C34.9167 2.16672 35.8333 1.45839 37 1.12505C38.1667 0.791721 39.3333 0.833388 40.5 1.25005L53.5 6.75005L66.5 1.25005C67.6667 0.833388 68.8333 0.791721 70 1.12505C71.1667 1.45839 72.0833 2.16672 72.75 3.25005L80 15.5001L93.75 18.5001C95 18.7501 96 19.3967 96.75 20.4401C97.5 21.4801 97.7917 22.6251 97.625 23.8751L96.25 38.0001L105.625 48.7501C106.458 49.6667 106.875 50.7501 106.875 52.0001C106.875 53.2501 106.458 54.3334 105.625 55.2501L96.25 66.0001L97.625 80.1251C97.7917 81.3751 97.5 82.5201 96.75 83.5601C96 84.6034 95 85.2501 93.75 85.5001L80 88.5L72.75 100.75C72.0833 101.833 71.1667 102.542 70 102.875C68.8333 103.208 67.6667 103.167 66.5 102.75L53.5 97.25L40.5 102.75C39.3333 103.167 38.1667 103.208 37 102.875C35.8333 102.542 34.9167 101.833 34.25 100.75L27 88.5ZM44.75 66.2501C45.6667 67.1667 46.8333 67.6251 48.25 67.6251C49.6667 67.6251 50.8333 67.1667 51.75 66.2501L73 45.0001C74 44.0001 74.5 42.8117 74.5 41.4351C74.5 40.0617 74 38.8751 73 37.8751C72 36.8751 70.8133 36.3751 69.44 36.3751C68.0633 36.3751 66.875 36.8751 65.875 37.8751L48.25 55.5001L41 48.3751C40 47.4584 38.8133 47.0201 37.44 47.0601C36.0633 47.1034 34.9167 47.5834 34 48.5001C33.0833 49.4167 32.625 50.5834 32.625 52.0001C32.625 53.4167 33.0833 54.5834 34 55.5001L44.75 66.2501Z"
                    fill="#14CD30"
                  />
                </svg>
                <div className="user-create-success-text-block">
                  <div className="user-create-success-text-block-name">
                    <p>{`${t('userCreateSuccessOne')}`} </p>

                    {successCreateModalPayload?.roleId &&
                    successCreateModalPayload?.roleId == 8 ? (
                      <div className="user-create-success-copy-block-pointer">
                        <Tooltip
                          PopperProps={{
                            disablePortal: true,
                          }}
                          open={copyToClipMess}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          title={t('copied')}
                        >
                          <CopyToClipboard
                            text={resendData}
                            onCopy={() => handleOpenTooltip()}
                          >
                            <span className={'d-flex'}>
                              <p
                                className={
                                  'user-create-success-username-highlight'
                                }
                              >{`${successCreateModalPayload.firstName} ${successCreateModalPayload.lastName}`}</p>
                            </span>
                          </CopyToClipboard>
                        </Tooltip>

                        <CopyToClipboard
                          text={resendData}
                          onCopy={() => handleOpenTooltip()}
                        >
                          <span>
                            <i class="las la-copy user-create-success-copy-icon"></i>
                          </span>
                        </CopyToClipboard>
                      </div>
                    ) : (
                      <div className={'user-create-success-copy-block'}>
                        <p
                          className={'user-create-success-username'}
                        >{`${successCreateModalPayload.firstName} ${successCreateModalPayload.lastName}`}</p>
                      </div>
                    )}
                  </div>
                  <p>
                    {successCreateModalIsEdit
                      ? t('userUpdateSuccessTwo')
                      : t('userCreateSuccessTwo')}
                  </p>
                </div>
                <div className="user-create-success-btn-wrapper">
                  <button
                    className="user-create-success-card"
                    onClick={async () => {
                      let userData = {};

                      await useFetchingLoader(async () => {
                        userData = await http.get(
                          `users/getUserById/${successCreateModalPayload.id}`
                        );
                      });
                      userData = userData.response.data;
                      handleCloseSuccessCreateModal();
                      handleViewMore({
                        userId: userData.id,
                        'Employee name': [
                          userData.firstName,
                          userData.lastName,
                        ].join(' '),
                        firstName: userData.firstName,
                        lastName: userData.lastName,
                        employeeId: userData.employeeId,
                        cardId: userData.cardId,

                        kitchensIds: Kitchens?.filter((obj) =>
                          userData?.kitchens.some((el) => el.id === obj.id)
                        ).map((obj) => obj.id),
                        createdAt:
                          userData.createdAt?.split('T')[0] ?? undefined,
                        Kitchens: Kitchens?.filter((obj) =>
                          userData?.kitchens.some((el) => el.id === obj.id)
                        )
                          .map((obj) => obj.name)
                          ?.join(','),
                        email: userData.email,
                        Contractor: (contractorList
                          ? contractorList.find(
                              (el) => el.id === userData.contractors[0]?.id
                            )
                          : {}
                        )?.id,
                        allContractors: contractorList
                          ? contractorList.filter((obj1) =>
                              userData.contractors.some(
                                (obj2) => obj2.id === obj1.id
                              )
                            )
                          : [],
                        Status: UserStatuses.find(
                          (el) => el.id === userData.userStatusId
                        )?.display,
                        Contractors: userData.contractors || null,
                        phone: userData.phone?.split(' ').join(''),
                        creditCard: userData.creditCard,
                        magneticStripe: userData?.magneticStripe,
                      });
                    }}
                  >
                    {t('showUserCard')}
                  </button>
                  {!successCreateModalIsEdit ? (
                    <button
                      className="user-create-success-add"
                      onClick={async () => {
                        let userData = {};
                        await useFetchingLoader(async () => {
                          userData = await http.get(
                            `users/getUserById/${successCreateModalPayload.id}`
                          );
                        });
                        userData = userData.response.data;
                        const kitchensIds = Kitchens?.filter((obj) =>
                          userData?.kitchens.some((el) => el.id === obj.id)
                        ).map((obj) => obj.id);
                        let ids = kitchensIds;
                        /* let newMealTypeDateArray = [];
                        if (kitchensIds?.length) {
                          kitchensIds?.forEach((el) => {
                            let newMealTypeInfo = getMealTypes?.filter(
                              (elInfo) => elInfo?.kitchenId === el
                            );
                            newMealTypeDateArray = [
                              ...newMealTypeDateArray,
                              ...newMealTypeInfo,
                            ];
                          });
                        }*/
                        handleCloseSuccessCreateModal();
                        setKitchensIds(ids);
                        if (userData['RoleId'] === 6) {
                          setShowContractor(true);
                        }
                        setPayload({
                          kitchensIds: ids,
                          contractors: userData.contractors,
                          status: UserStatuses.find(
                            (el) => el.id === userData.userStatusId
                          )?.display,
                          magneticStripe:
                            userData.roleId === 7 || userData.roleId === 6
                              ? userData?.magneticStripe
                              : '',
                        });

                        handleOpen('recreate');
                      }}
                    >
                      {t('createAnotherUser')}
                    </button>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </Typography>
          </Box>
        </Modal>
      </div>
    </div>
  );
};

const ActionButton = ({
  e,
  handleOpen,
  setPayload,
  setUpdate,
  setIsEdit,
  t,
  kitchensIds,
  setKitchensIds,
  Kitchens,
  ResendVerification,
  handleDeleteUser,
  CopyDataUrl,
  handleConfirmation,
  UserStatuses,
}) => {
  setUpdate(e.data);
  setIsEdit(true);
  return (
    <div
      className={`action-btn ${
        e.data.Status === 'Created' || e.data.Status === 'Pending'
          ? 'action-btn'
          : ''
      } `}
    >
      <button
        onClick={() => {
          // e.data['Kitchens'].length
          //   ? setKitchensIds(
          //       Kitchens.map((el) => {
          //         if (e.data['Kitchens'].includes(el.name)) return el.id;
          //       })
          //     )
          //   : setKitchensIds([]);
          let ids = [];
          if (e.data['Kitchens'].length) {
            Kitchens.forEach((el) => {
              if (e.data['Kitchens'].includes(el.name)) {
                ids.push(el.id);
              }
            });
          }
          setKitchensIds(ids);
          setPayload({
            userId: e.data['userId'],
            card: e.data['cardId'],
            email: e.data['email'],
            fName: e.data['firstName'],
            lName: e.data['lastName'],
            phone: e.data['phone']
              ? e.data['phone'].split('-').join('')
              : e.data['phone'],
            // kitchensIds: e.data['Kitchens'],
            kitchensIds: ids,
            contractors: e.data['Contractors'],
            employeeId: e.data['employeeId'],
            password: e.data['password'],
            status: e.data['Status'],
            contractorsIds: [],
          });
          handleOpen();
        }}
        className="edit-btn"
      >
        {t('editbutton')}
      </button>
      <button
        className="delete-btn"
        // onClick={() => handleDeleteUser(e.data['userId'])}
        onClick={() => handleConfirmation(e.data['userId'])}
      >
        {t('deletebutton')}
      </button>
      {UserStatuses?.find((el) => el.display === e.data['Status'])?.value ===
        'created' ||
      UserStatuses?.find((el) => el.display === e.data['Status'])?.value ===
        'pending' ? (
        <button
          className="resend-btn"
          onClick={() => ResendVerification(e.data)}
        >
          Resend
        </button>
      ) : (
        ''
      )}
      {UserStatuses?.find((el) => el.display === e.data['Status'])?.value ===
        'created' ||
      UserStatuses?.find((el) => el.display === e.data['Status'])?.value ===
        'pending' ? (
        <button className="copy-btn" onClick={() => CopyDataUrl(e.data)}>
          Copy URL
        </button>
      ) : (
        ''
      )}
    </div>
  );
};

const ViewMore = ({ e, handleViewMore, allContractors, contractors }) => {
  return (
    <div className="view-more-btn">
      <img
        src={next}
        alt="next"
        onClick={() =>
          handleViewMore({ ...e.data, allContractors, contractors })
        }
      />
    </div>
  );
};

const KitcheCellRender = ({ e }) => {
  return (
    <div className="name-section">
      <p>{e?.data?.Kitchens?.length > 0 ? e?.data?.Kitchens : '--'}</p>
    </div>
  );
};
const ContractorCellRender = ({ e }) => {
  return (
    <div className="name-section">
      <p>
        {Array.isArray(e?.data.Contractors) && e?.data.Contractors?.length > 0
          ? e?.data?.Contractors.map((el) => el.name).join(',')
          : '--'}
      </p>
    </div>
  );
};
const CreditCard = ({ e }) => {
  return (
    <div className={`credit-card-option`}>
      <input type="checkbox" checked={e?.data?.creditCard} />
    </div>
  );
};
const PopupCellRenderer = ({
  e,
  handleOpen,
  setPayload,
  setUpdate,
  setIsEdit,
  t,
  handleDeleteUser,
  kitchensIds,
  setKitchensIds,
  Kitchens,
  Roles,
  UserStatuses,
  ResendVerification,
  CopyDataUrl,
  handleConfirmation,
  contractorList,
  handleViewMore,
}) => {
  const tippyRef = useRef();
  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  const dropDownContent = (
    <div
      className={`action-btn ${
        e.data.Status === 'Created' || e.data.Status === 'Pending'
          ? 'action-btn'
          : ''
      } `}
      style={{ position: 'relative' }}
    >
      <div className="menu-container">
        <div className="menu-item">
          <div
            onClick={() => {
              // e.data['Kitchens'].length
              //   ? setKitchensIds(
              //       Kitchens.map((el) => {
              //         if (e.data['Kitchens'].includes(el.name)) return el.id;
              //       })
              //     )
              //   : setKitchensIds([]);
              let ids = [];
              if (e.data['Kitchens'].length) {
                Kitchens.forEach((el) => {
                  if (e.data['Kitchens'].includes(el.name)) {
                    ids.push(el.id);
                  }
                });
              }
              setKitchensIds(ids);
              setPayload({
                userId: e.data['userId'],
                card: e.data['cardId'],
                email: e.data['email'],
                fName: e.data['firstName'],
                lName: e.data['lastName'],
                phone: e.data['phone']
                  ? e.data['phone'].split('-').join('')
                  : e.data['phone'],
                // kitchensIds: e.data['Kitchens'],
                kitchensIds: ids,
                contractors: e.data['Contractors'],
                employeeId: e.data['employeeId'],
                password: e.data['password'],
                status: e.data['Status'],
                contractorsIds: [],
              });
              handleOpen();
            }}
            // className="edit-btn"
          >
            {t('editbutton')}
          </div>
        </div>
        <div className="menu-item">
          <div
            // className="delete-btn"
            // onClick={() => handleDeleteUser(e.data['userId'])}
            onClick={() => handleConfirmation(e.data['userId'])}
          >
            {t('deletebutton')}
          </div>
        </div>

        {UserStatuses?.find((el) => el.display === e.data['Status'])?.value ===
          'created' ||
        UserStatuses?.find((el) => el.display === e.data['Status'])?.value ===
          'pending' ? (
          <div className="menu-item">
            <div
              // className="resend-btn"
              onClick={() => ResendVerification(e.data)}
            >
              Resend
            </div>
          </div>
        ) : (
          ''
        )}

        {UserStatuses?.find((el) => el.display === e.data['Status'])?.value ===
          'created' ||
        UserStatuses?.find((el) => el.display === e.data['Status'])?.value ===
          'pending' ? (
          <div className="menu-item">
            <div onClick={() => CopyDataUrl(e.data)}>Copy URL</div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );

  const onClickHandler = (option) => {
    hide();
    if (option === 'create') {
      props.api.applyTransaction({
        add: [{}],
      });
    }
    if (option === 'delete') {
      props.api.applyTransaction({ remove: [props.data] });
    }

    if (option === 'edit') {
      props.api.startEditingCell({
        rowIndex: props.rowIndex,
        colKey: 'make',
      });
    }
  };

  return (
    <Tippy
      ref={tippyRef}
      content={dropDownContent}
      visible={visible}
      onClickOutside={hide}
      allowHTML={true}
      arrow={false}
      appendTo={document.body}
      interactive={true}
      placement="right"
    >
      <i
        className="fa fa-ellipsis-v"
        aria-hidden="true"
        // onClick={() => handleDropdownClick(e.data?.userId)}
        onClick={visible ? hide : show}
      ></i>
    </Tippy>
  );
};

const mapStateToProps = (state) => ({
  kitchen: state.kitchens.current,
  user: state.user,
  Roles: state.app.enums.Role,
  UserStatuses: state.app.enums.UserStatus,
  Kitchens: state.kitchens.options,
  allUsers: state.user.allUsers,
  usersTotal: state.user.usersTotal,
  cardGenerationUrlForUser: state.companyConfig.cardGenerationUrlForUser,
  employeeNumberLengthForUser: state.companyConfig.employeeNumberLengthForUser,
  cardNumberLengthForUser: state.companyConfig.cardNumberLengthForUser,
  companyName: state.companyConfig.companyName,
  companyId: state.companyConfig.companyId,
  isStrippedCard: state.companyConfig.isStrippedCard,
  cibusEnabled: state.companyConfig.paymentsSettings.cibusEnabled,
  fieldNamesSettings: state.companyConfig.fieldNamesSettings,
});

const mapDispatchToProps = {
  setLayoutOptions,
  useLoader,
  useFetchingLoader,
  showAlert,
  getUserConfigByCompanyId,
  getAllUser,
};
export default connect(mapStateToProps, mapDispatchToProps)(ContractorEmployee);
