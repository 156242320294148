import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router';
import DishForm from '../../components/Forms/DishForm';
import { connect } from 'react-redux';
import http from '../../services/http';
import axios from 'axios';
import {
  setLayoutOptions,
  useFetchingLoader,
  useLoader,
} from '../../store/app/actions';
import AppConfig from '../../constants/Config';
import { getAssetUrl } from '../../services/aws';
import { sleep } from '../../services/utils';
import WEIcon from '../../components/WE-IconButton';
import WEBackButton from '../../components/WE-BackButton';
import { Alert } from '../../components/Dialogs/SwalDialogs';
import defaultImage from '../../assets/images/defaultImage.png';
import { getCsrfToken } from '../../services/csrfToken';
import BackLink from '../../components/BackLink';
import '../../style/addeditPopup.scss';
import Dropzone from 'react-dropzone';
import { validateAddDish, validateEditDish } from '../../services/validators';
import * as SwalDialogs from '../../components/Dialogs/SwalDialogs';

const SelectStyles = {
  control: (provided, state) => ({
    ...provided,
    height: 'auto',
    minHeight: 40,
    backgroundColor: '#f0f4f8',
    border: '1px solid #e3e3e3',
    boxShadow: 'none',
    borderRadius: '5px',
    color: 'black',
  }),
};

const AddEditDishPopup = ({
  handleClose,
  show,
  DishStatuses,
  languages,
  DishCategories,
  kitchen,
  dish,
  setAddEditPopup,
  translations,
  setTranslations,
  tags,
  setTags,
  selectedTags,
  setSelectedTags,
  fileValue,
  setFileValue,
  errors,
  setErrors,
  values,
  setValues,
  InitialValues,
  setDummy,
  updateData,
  pastFlag,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { dishId } = useParams();
  const location = useLocation();
  const { backText, backLink } = location.state ?? {};
  useEffect(() => {
    const fetchData1 = async () => {
      let title = dishId ? t('editDish') : t('addDish');
      setLayoutOptions({ title });
    };
    fetchData1();
  }, [dishId]);
  useEffect(() => {
    const fetchData1 = async () => {
      await useLoader(() => sleep());
    };
    fetchData1();
  }, []);

  const DishStatusMap = DishStatuses.reduce((acc, dish) => {
    return { ...acc, [dish.value]: dish.id };
  }, {});

  const handleDishName = (id, value, name) => {
    if (name === 'dishName') {
      setTranslations({ languageId: id, name: value });
    }
    if (name === 'description') {
      setTranslations({ ...translations, description: value });
    }
  };

  const makeImage = (imgText) =>
    imgText?.startsWith('http') ? imgText : getAssetUrl(imgText);

  useEffect(() => {
    const fetchData1 = async () => {
      const filterPayload = {
        page: 1,
        limit: 1000,
        keyword: '',
      };
      const { data: _tags } = await http.get('tags', filterPayload);
      setTags(_tags?.filter((tag) => tag.status));
      let dummyData = {};

      if (dish) {
        const {
          image,
          dishCategoryId,
          translations,
          price,
          tags: selectedTag,
        } = await http.get(`dishes/${dish}`, { language: 'all' });
        setSelectedTags(selectedTag.map((tag) => tag.id));
        translations?.forEach((el) => (dummyData[el.languageId] = el));
        setTranslations((prev) => {
          return { ...prev, ...dummyData };
        });
        setFileValue(image);
        let catValue = DishCategories.filter((el) => el.id === dishCategoryId);
        setValues({ ...values, dishCategoryId: catValue[0]?.id });
        setValues({
          image: makeImage(image),
          dishCategoryId,
          translations,
          price,
          tags: selectedTag.map((tag) => tag.id),
        });
      } else {
        setValues(InitialValues);
      }
    };
    fetchData1();
  }, [dish]);

  const handleSubmit = async () => {
    const { isValid, errors } = validateAddDish(values);
    let res;
    setErrors(errors);
    if (isValid) {
      try {
        var formData = new FormData();
        Object.keys(values).forEach((item) => {
          if (item === 'translations') {
            formData.append(item.toString(), JSON.stringify(values[item]));
          } else formData.append(item.toString(), values[item]);
        });
        if (dish) {
          setAddEditPopup(false);
          formData.set(
            'image',
            fileValue && fileValue[0].path ? fileValue[0] : fileValue
          );
          formData.set('tags', JSON.stringify(selectedTags));
          // let dd = await http.put(`dishes/${dish}`, formData)

          let dd = await axios.put(
            `${AppConfig.server.url}/dishes/${dish}`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${JSON.parse(
                  localStorage.getItem('token')
                )}`,
                'X-Site-Url': window.location.href,
                'X-Org-Id': localStorage.getItem('companyId') ?? null,
              },
            }
          );
          await SwalDialogs.Dialog({
            title: t('dishUpdatedMessage'),
            type: 'success',
            onClose: () => {
              if (window.location.hash.includes('menuConstruction')) {
                setDummy((prev) => !prev);
              }
              setValues(InitialValues);
              setErrors({});
              setSelectedTags([]);
              handleClose(true);
              // window.location.reload();
              //updateData();
            },
            buttons: {
              showCancelButton: false,
              confirmButtonText: t('replaceOrderBtnLabel'),
            },
          });
        } else {
          setAddEditPopup(false);
          formData.append('kitchenId', kitchen.id);
          formData.append('tags', JSON.stringify(selectedTags));
          formData.append('dishStatusId', DishStatusMap.active);
          formData.set('image', fileValue ? fileValue[0] : null);
          res = await axios.post(
            `${AppConfig.server.url}/dishes/create`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${JSON.parse(
                  localStorage.getItem('token')
                )}`,
                'X-Site-Url': window.location.href,
                'X-Org-Id': localStorage.getItem('companyId') ?? null,
              },
            }
          );
          SwalDialogs.Dialog({
            title: t('dishAddedMessage'),
            type: 'success',
            onConfirm: () => {
              if (window.location.hash.includes('menuConstruction')) {
                setDummy((prev) => !prev);
              }
              setValues(InitialValues);
              setErrors({});
              setSelectedTags([]);
              handleClose(true);
            },
            buttons: {
              showCancelButton: false,
              confirmButtonText: t('replaceOrderBtnLabel'),
            },
          });
        }
      } catch (err) {
        Alert({ error: res?.message ? res?.message : err });
      }
    }
  };

  const handleFields = (e, index = -1) => {
    const { name, value } = e.target;
    if (name === 'tags') {
      let filtertagId = tags.filter((el) => el.displayName === value);
      setValues({ ...values, [name]: [filtertagId[0].id] });
    } else if (name === 'dishCategoryId') {
      setValues({ ...values, [name]: value });
    } else if (name === 'price') {
      e.target.value = Number(value.replace(/\D/g, ''));
      setValues({ ...values, [name]: Math.abs(value) });
    } else if (index !== -1) {
      const prevTransalations = [...values.translations];
      prevTransalations[index][name] = value;
      setValues({ ...values, translations: prevTransalations });
    } else {
      setValues({ ...values, [name]: value });
    }
  };
  const handleImage = (e) => {};

  return (
    <Modal
      show={show}
      onHide={() => {
        handleClose(false);
        setValues(InitialValues);
        setErrors({});
        setSelectedTags([]);
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="add-edit-popup"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {dish ? t('updateDish') : t('addDish')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="add-edit-dish">
          <div className="dish-left-section">
            {languages.length > 0 &&
              languages?.map((lang, index) => (
                <Form.Group
                  className="mb-3 form-group"
                  controlId="exampleForm.ControlInput1"
                  key={lang + index}
                >
                  <Form.Label>{lang?.displayName}</Form.Label>
                  <Form.Control
                    type="text"
                    disabled={pastFlag}
                    placeholder="Enter Name"
                    as="textarea"
                    name="name"
                    defaultValue={translations[lang.id]?.name || ''}
                    onBlur={(e) => handleFields(e, index)}
                  />
                  {errors && errors[`translations[${index}].name`] && (
                    <p className="error-msg">
                      {errors[`translations[${index}].name`]}
                    </p>
                  )}
                  <Form.Control
                    disabled={pastFlag}
                    name="description"
                    type="text"
                    as="textarea"
                    placeholder="Short Description"
                    defaultValue={translations[lang.id]?.description || ''}
                    onBlur={(e) => handleFields(e, index)}
                  />
                  {errors && errors[`translations[${index}].description`] && (
                    <p className="error-msg">
                      {errors[`translations[${index}].description`]}
                    </p>
                  )}
                </Form.Group>
              ))}
          </div>
          <div className="dish-right-section">
            <Form.Group
              className="mb-3 form-group"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>{t('price')}</Form.Label>
              <Form.Control
                disabled={pastFlag}
                type="number"
                name="price"
                placeholder="price"
                onChange={handleFields}
                value={values?.price}
              />
            </Form.Group>
            <Form.Group
              className="mb-3 form-group"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>{t('category')} </Form.Label>
              <Form.Select
                disabled={pastFlag}
                aria-label="Default select example"
                name="dishCategoryId"
                onChange={handleFields}
                value={
                  Array.isArray(values.dishCategoryId) &&
                  values.dishCategoryId.length
                    ? values.dishCategoryId[0]
                    : values.dishCategoryId
                }
              >
                {DishCategories.map((el, i) => (
                  <option value={el.id} key={i}>
                    {el.display}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group
              className="mb-3 form-group"
              controlId="exampleForm.ControlInput1"
            >
              <label>{t('selectTags')}</label>
              <Select
                isMulti
                disabled={pastFlag}
                name={'tags'}
                value={selectedTags.map((el) => {
                  return {
                    value: el,
                    label: tags?.find((ele) => ele.id === el)?.displayName,
                  };
                })}
                onChange={(newData) => {
                  setSelectedTags(newData.map((k) => k.value));
                }}
                options={tags.map((tag) => {
                  return { value: tag.id, label: tag.displayName };
                })}
                placeholder={t('selectTags')}
                styles={SelectStyles}
              />
            </Form.Group>
            <div className="upload-image">
              <label>
                <UploadfileSection
                  fileValue={fileValue}
                  disabled={pastFlag}
                  setFileValue={setFileValue}
                  t={t}
                />
              </label>
            </div>
          </div>
        </div>
        {!pastFlag && (
          <div className="add-btn">
            <Button type="submit" onClick={handleSubmit}>
              {dish ? t('update') : t('add')}
            </Button>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};
export default AddEditDishPopup;

const UploadfileSection = ({ fileValue, setFileValue, t }) => {
  const [preview, setPreview] = useState(null);
  const handleuploadImage = (value) => {
    setFileValue(value);

    const ImageUrl = window.URL.createObjectURL(value[0]);
    setPreview(ImageUrl);
  };

  const removeFile = (index, e) => {
    e.preventDefault();
    let dd = null;

    // let dd =
    //   fileValue && fileValue.length > 0
    //     ? fileValue.filter((val, i) => i !== index)
    //     : null;
    setFileValue(dd);
  };

  return (
    <div className={`dropzone dropzone--single`}>
      <Dropzone
        accept=".jpg, .jpeg, .png, .doc, .docx, .pdf"
        name={name}
        multiple={false}
        onDrop={(fileToUpload) => {
          handleuploadImage(fileToUpload);
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} className="dropzone__input">
            {(!fileValue || fileValue == 'null' || fileValue.length === 0) && (
              <div className="dropzone__drop-here">
                <span>{t('dropImage')}</span>
                <span className="lnr lnr-upload" />
              </div>
            )}
            <input {...getInputProps()} />
          </div>
        )}
      </Dropzone>
      {fileValue === null || fileValue === undefined ? (
        <>
          {fileValue && fileValue.length ? (
            <aside className="dropzone__img">
              <img src={defaultImage} alt="drop-img" />
              <p className="dropzone__img-name">{fileValue[0]?.name}</p>
              <button
                className="dropzone__img-delete"
                type="button"
                onClick={(e) => removeFile(0, e)}
              >
                {t('remove')}
              </button>
            </aside>
          ) : (
            <aside className="dropzone__img">
              <img src={defaultImage} alt="drop-img" />
              <button
                className="dropzone__img-delete"
                type="button"
                onClick={(e) => removeFile(0, e)}
              >
                {t('remove')}
              </button>
            </aside>
          )}
        </>
      ) : (
        <>
          {fileValue && fileValue.length ? (
            <aside className="dropzone__img">
              <img
                src={
                  preview
                    ? preview
                    : fileValue == 'null'
                      ? defaultImage
                      : fileValue
                }
                alt="drop-img"
              />
              <p className="dropzone__img-name">{fileValue[0].name}</p>
              <button
                className="dropzone__img-delete"
                type="button"
                onClick={(e) => removeFile(0, e)}
              >
                {t('remove')}
              </button>
            </aside>
          ) : (
            ''
          )}
        </>
      )}
    </div>
  );
};
