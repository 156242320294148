import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import calendarIcon from '../assets/images/calendar.svg';
// import BackLink from './BackLink';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { isMobile } from '../services/mediaQueries';
import KitchenDropdown from './Subheaders/KitchenDropdown';
import dates from '../services/dates';
import LanguageSelection from './Forms/LanguageSelection';

// @ts-ignore
const SubHeader = ({
  // title,
  user,
  kitchens,
  kitchen,
  setKitchen,
  showDatePicker,
  showKitchen,
  date,
  mealTypeId,
  mealTypeIdFromRedux,
  MealTypes,
  dateClicked,
  isDatePickerClickable,
  dateRangeValues,
  currentLanguage,
  userKitchen,
}) => {
  let title = '';
  const { t } = useTranslation();
  const [menuActive, toggleMenu] = useState(false);
  const { backText, backLink } = location.state ?? {};
  const MealTypesMap = MealTypes
    ? MealTypes.reduce((acc, dish) => {
        return { ...acc, [dish.id]: dish.display };
      }, {})
    : {};
  const queryParams = new URLSearchParams(window.location.search);
  const pathname = window.location;
  if (pathname.hash.includes('#/userManagement')) {
    showKitchen = false;
    title = t('userManagement');
  } else if (pathname.hash.includes('#/user-details')) {
    showKitchen = false;
    title = t('userManagement');
  } else if (
    pathname.hash.includes('#/employeeManagement') ||
    pathname.hash.includes('#/employee-details')
  ) {
    showKitchen = false;
    title = t('employeeManagement');
  } else if (pathname.hash.includes('#/kitchensManagement')) {
    showKitchen = false;
    title = t('kitchensManagement');
  } else if (
    pathname.hash.includes('#/reservationManagement') ||
    pathname.hash.includes('#/reservation-details')
  ) {
    title = t('detailedReservations');
  } else if (pathname.hash.includes('#/reservationByUser')) {
    title = t('reservationByUser');
  } else if (pathname.hash.includes('#/reservationByMeal')) {
    title = t('reservationByMeal');
  } else if (pathname.hash.includes('#/audit')) {
    title = t('audit');
  } else if (pathname.hash.includes('#/languages')) {
    title = t('languages');
  } else if (pathname.hash.includes('#/attendance')) {
    title = t('attendanceTitle');
  } else if (pathname.hash.includes('#/salaryReport')) {
    title = t('salaryFileReport');
  } else if (pathname.hash.includes('#/guestUsers')) {
    title = t('guestUsers');
  } else if (pathname.hash.includes('#/mealtypes')) {
    title = t('mealTimeManagement');
  } else if (pathname.hash.includes('#/menuConstruction')) {
    title = t('mealManagement');
  } else if (pathname.hash.includes('#/copyPreviousMeal')) {
    title = t('CopyFromPreviousDish');
  } else if (
    pathname.hash.includes('#/mealDishes') ||
    pathname.hash.includes('#/addDishToMeal')
  ) {
    title = t('chooseYourDish');
  } else if (pathname.hash.includes('#/profile')) {
    title = t('profile');
  } else if (
    pathname.hash.includes('#/contractorManagement') ||
    pathname.hash.includes('#/viewContractor')
  ) {
    showKitchen = false;
    title = t('contractorManagement');
  } else if (pathname.hash.includes('#/mealManagement')) {
    title = t('futureMeals');
  } else if (pathname.hash.includes('#/weekView')) {
    title = t('weekView');
  } else if (pathname.hash.includes('#/factory')) {
    title = t('factoryManagement');
  } else if (pathname.hash.includes('#/invite')) {
    title = t('inviteManagement');
  } else if (pathname.hash.includes('#/pastMeals')) {
    title = t('pastMeals');
  } else if (pathname.hash.includes('#/security')) {
    let url = window.location.href;
    let [id, name] = url.split('?')[1].split('&');
    let currentKitchen = kitchens.filter((el) => el.id == id);
    title = currentKitchen[0]?.name ? currentKitchen[0]?.name : name;
  } else if (pathname.hash.includes('#/contractorEmployee')) {
    title = t('contractorEmployeeManagement');
  } else if (pathname.hash.includes('#/wallet')) {
    title = t('userWalletManagement');
  } else if (pathname.hash.includes('#/tagManagement')) {
    title = t('tagManagement');
  } else if (
    pathname.hash.includes('#/profile') ||
    pathname.hash.includes('#/settings')
  ) {
    title = t('profile');
  } else if (pathname.hash.includes('#/privacy-policy')) {
    title = t('privacyPolicy');
  } else if (pathname.hash.includes('#/terms-condition')) {
    title = t('termsAndCondition');
  } else if (pathname.hash.includes('#/controlPanel')) {
    title = t('controlPanel');
  } else if (
    pathname.hash.includes('#/lobby') &&
    user.roleId !== 4 &&
    user.roleId !== 7 &&
    user.roleId !== 6
  ) {
    title = t('lobby');
  } else if (pathname.hash.includes('#/dishManagement')) {
    title = t('dishManagement');
  } else if (pathname.hash.includes('#/notifications')) {
    title = t('notifications');
  } else if (pathname.hash.includes('#/allReviews')) {
    title = t('reviewManagement');
  } else if (pathname.hash.includes('#/addExtrasDishToMeal')) {
    title = t('addExtras');
  } else if (pathname.hash === '#/logViewer') {
    title = t('logViewer');
  } else if (pathname.hash.includes('#/logviewer-details')) {
    title = t('logViewerDetails');
  } else if (pathname.hash.includes('#/paymentManagement')) {
    title = t('payment');
  } else if (pathname.hash.includes('#/addReview')) {
    title = t('addReview');
  } else if (pathname.hash.includes('#/kitchen-devices')) {
    showKitchen = false;
    title = t('kitchenDevices');
  }
  return (
    <>
      <Helmet>
        <meta property="og:title" content={title} />
        <title>{title}</title>
      </Helmet>
      <div className={`${title ? 'sub-header-wrapper' : ''}`}>
        <div className="container">
          <section
            className={`sub-header ${showDatePicker ? 'has-date-picker' : ''}`}
          >
            <Title
              title={title}
              subtitle={
                <DatePicker
                  MealTypesMap={MealTypesMap}
                  dateClicked={dateClicked}
                  date={date}
                  mealTypeId={mealTypeId}
                  showDatePicker={showDatePicker}
                  isDatePickerClickable={isDatePickerClickable}
                  dateRangeValues={dateRangeValues}
                  kitchen={kitchen}
                  mealTypeIdFromRedux={mealTypeIdFromRedux}
                />
              }
            />

            {/*{!isMobile() && <Title title={title}/>}*/}
            {/*{!isMobile() && showDatePicker && <DatePicker date={date} dateClicked={dateClicked}/>}*/}

            {/* <div
          className={`kitchens-list ${showKitchen ? 'show-kitchen' : ''} ${
            kitchens.length > 1 ? 'pointer' : ''
          }`}
          onClick={() => toggleMenu(!menuActive)}
          onMouseLeave={() => toggleMenu(false)}
        >
          {showKitchen && (
            <KitchenDropdown
              curKitchen={kitchen}
              kitchens={kitchens}
              menuActive={menuActive}
              setKitchenCB={setKitchen}
              user={user}
              currentLanguage={currentLanguage}
              userKitchen={userKitchen}
            />
          )}
        </div> */}
            {!localStorage.getItem('token') ? <LanguageSelection /> : ''}
          </section>

          {isMobile() && localStorage.getItem('token') ? (
            <div className={`sub-header mobile`}>
              <Title
                title={title}
                subtitle={
                  <DatePicker
                    MealTypesMap={MealTypesMap}
                    dateClicked={dateClicked}
                    date={date}
                    mealTypeId={mealTypeId}
                    isDatePickerClickable={isDatePickerClickable}
                    dateRangeValues={dateRangeValues}
                    showDatePicker={showDatePicker}
                    kitchen={kitchen}
                    mealTypeIdFromRedux={mealTypeIdFromRedux}
                  />
                }
              />
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  );
};

const Title = ({ title, subtitle }) => {
  return (
    <div className="title">
      {title}
      {subtitle}
    </div>
  );
};

const DatePicker = ({
  showDatePicker,
  dateClicked,
  MealTypesMap,
  date,
  mealTypeId,
  isDatePickerClickable,
  dateRangeValues,
  kitchen,
  mealTypeIdFromRedux,
}) => {
  return (
    <>
      {showDatePicker &&
        (date || dateRangeValues) &&
        (mealTypeId ?? mealTypeIdFromRedux) && (
          <div
            className={`title-date ${isDatePickerClickable ? 'clickable' : ''}`}
            onClick={isDatePickerClickable ? dateClicked : undefined}
          >
            {`${dateRangeValues ? dateRangeValues : dates.format(date)} | ${
              MealTypesMap[mealTypeId ?? mealTypeIdFromRedux]
            }`}{' '}
            | {kitchen.name}
          </div>
        )}
    </>
  );
};

// const DatePicker = ({date, dateClicked}) => {
//
//     return (<div className="date-picker-wrapper">
//         <div className="date-picker" onClick={() => {
//             dateClicked()
//         }}>
//             <div className="text">{moment(date).format('DD.MM.YYYY')}</div>
//             <img className="icon" src={calendarIcon} alt="" onClick={() => {
//             }}/>
//         </div>
//     </div>);
// };

export default SubHeader;
