import React, { Fragment } from 'react';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';

import LoginScreen from '../screens/Login';
import Signup from '../screens/Signup';
import Layout from '../components/Layout';

/* Client screens */
import Profile from '../screens/client/Profile';
import ClientLobby from '../screens/client/Lobby';
import MealDishes from '../screens/client/MealDishes';
import AddReview from '../screens/client/AddReview';
import MealTime from '../screens/client/MealTime';
import OrderSummary from '../screens/client/OrderSummary';
import CafeteriaOrderSummary from '../screens/client/CafeteriaOrderSummary';
import MyMeals from '../screens/client/MyMeals';
import OrderVoucher from '../screens/client/Voucher';
import Wallet from '../screens/client/Wallet';
import EmployeePaymentManagement from '../screens/client/EmployeePaymentManagement';

/* Kitchen Manager Screens  */

import LobbyKitchenManager from '../screens/KitchenManager/Lobby.jsx';
import DishManagement from '../screens/KitchenManager/DishManagement.jsx';
import AddEditDish from '../screens/KitchenManager/AddEditDish';
import MenuConstruction from '../screens/KitchenManager/MenuConstruction';
import AddDishToMeal from '../screens/KitchenManager/AddDishToMeal';
import MealManagement from '../screens/KitchenManager/MealManagement';
import WeekView from '../screens/KitchenManager/WeekView';
import ReservationManagement from '../screens/KitchenManager/ReservationManagement';
import AttendancePage from '../screens/KitchenManager/AttendancePage.tsx';
import SalaryReport from '../screens/KitchenManager/SalaryReport';
import MealReport from '../screens/KitchenManager/MealReport';
import ControlPanel from '../screens/ControlPanel/ControlPanel';
import PaymentManagement from '../screens/KitchenManager/PaymentManagement';
import ContractorManagement from '../screens/SiteManager/ContractorManagement';

/* Site Manager */
import LobbySiteManager from '../screens/SiteManager/Lobby.jsx';
import UserManagement from '../screens/SiteManager/UserManagement';
import ResetPassword from '../screens/ResetPassword.jsx';
import ScrollToTop from '../components/ScrollToTop';
import KitchenManagement from '../screens/SiteManager/KitchenManagement';
import EmployeeManagement from '../screens/SiteManager/EmployeeManagement';
import TagManagement from '../screens/SiteManager/TagManagement';
import AddEditTag from '../screens/SiteManager/AddEditTag';
import SuccessfullySavedPaymentMethod from '../screens/client/SuccessfullySavedPaymentMethod';
import Security from '../screens/SiteManager/Security';
import TermsCondition from '../components/TermsCondition';
import PrivacyPolicy from '../components/PrivacyPolicy';
import MealTypes from '../screens/SiteManager/MealTypes';
import PrintReports from '../screens/KitchenManager/printReports';
import AllReviews from '../components/Review';
import ReviewsDetails from '../components/ReviewDetails';
import LogViewer from '../components/LogViewer';
import Notifications from '../components/Notifications';
import copyPreviousMeal from '../screens/KitchenManager/copyPreviousMeal';
import AddExtrasDishToMeal from '../screens/KitchenManager/AddExtrasDishtoMeal';
import { socket } from '../services/socket';
import PastMeals from '../screens/KitchenManager/PastMeals';
import ContractorEmployee from '../screens/SiteManager/ContractorEmployee';
import GuestUsers from '../screens/SiteManager/GuestUsers';
import MealTimePage from '../screens/Newfolder/MealTimePage';
import YourDish from '../screens/Newfolder/YourDish';
import MyOrdersPage from '../screens/Newfolder/MyOrdersPage';
import Settings from '../screens/profile/settings';
import ViewDetails from '../screens/SiteManager/viewDetails';
import ViewEmployeeDetails from '../screens/SiteManager/viewEmployeeDetails';
import ViewReservationDetails from '../screens/KitchenManager/viewReservationdetails';
import WalletManagement from '../screens/SiteManager/WalletManagement';
import Forget from '../screens/Login/forget';
import ContractDetails from '../screens/SiteManager/ContractDetails';
import LogViewerDetails from '../components/logViewDetails';
import CibusError from '../components/CibusError';
import kitchenDeviceManagement from '../screens/SiteManager/kitchenDeviceManagement';
import languageManagement from '../screens/SiteManager/languageManagement';
import auditManager from '../screens/SiteManager/auditManager';
import factoryManagement from '../screens/SiteManager/FactoryManagement';
import invitationManagement from '../screens/SiteManager/InvitationManagement';
import ReservationByUser from '../screens/KitchenManager/ByUserReservationManagement';
import ReservationByMeal from '../screens/KitchenManager/ByMealTypeReservationManagement';
import ReservationForAnotherUser from '../screens/SiteManager/ReservationForAnotherUser';

export default ({ user }) => {
  return (
    <Fragment>
      {/*<ScrollToTop />*/}
      <Switch>
        <Route
          exact
          path="/voucher/:orderId/:authoTokenURL?"
          component={OrderVoucher}
        />

        <Route exact path="/terms-condition/:lang" component={TermsCondition} />
        <Route exact path="/privacy-policy/:lang" component={PrivacyPolicy} />
        <Route path="/loginByToken" component={LoginScreen} />
        <Layout user={user} socket={socket}>
          <Route path="/login/:email?" component={LoginScreen} />
          <Route exact path="/forget" component={Forget} />
          <Route exact path="/signup" component={Signup} />
          <Route
            exact
            path="/update-password/:type/:token"
            component={ResetPassword}
          />

          <PrivateRoute user={user} />
        </Layout>
      </Switch>
    </Fragment>
  );
};

const getUserRoutes = (roleId, props, user, data) => {
  switch (roleId) {
    case 1:
      return <AdminRoutes {...props} user={user} data={data} />;
    case 2:
      return <SiteManagerRoutes {...props} user={user} data={data} />;
    case 3:
      return <KitchenManagerRoutes {...props} user={user} data={data} />;
    case 9:
      return <KitchenManagerRoutes {...props} user={user} data={data} />;
    case 10:
      return <SiteManagerRoutes {...props} user={user} data={data} />;
    default:
      return <ClientRoutes {...props} user={user} data={data} />;
  }
};

const PrivateRoute = ({ user, data }) => {
  return (
    <Route
      render={(props) => {
        return user && user?.userStatusId === 3 && 'kitchens' in user ? (
          getUserRoutes(user.roleId, props, user, data)
        ) : props.location.pathname.toLowerCase().indexOf('signup') !== -1 ||
          props.location.pathname.toLowerCase().indexOf('update-password') !==
            -1 ? (
          ''
        ) : (
          <>
            <Redirect to={{ pathname: '/login' }} />
          </>
        );
      }}
    />
  );
};

const ClientRoutes = ({ user, data }) => {
  return (
    <Switch>
      <Route exact path="/lobby" component={ClientLobby} socket={socket} />
      <Route exact path="/mealTime" component={MealTimePage} />
      <Route exact path="/orderSummary" component={YourDish} />
      <Route exact path="/myorders" component={MyOrdersPage} />

      {/* <Route
        exact
        path="/mealTime/:date?/:mealTypeId?"
        component={MealTime}
        socket={socket}
      /> */}
      <Route
        exact
        path="/mealDishes/:date/:mealTypeId/:editId?/:orderId?/:slotId?"
        component={MealDishes}
        socket={socket}
      />
      {/* <Route
        exact
        path="/orderSummary/:mealDishId/:slotId?"
        component={OrderSummary}
        socket={socket}
      /> */}
      <Route
        exact
        path="/cafeteriaOrderSummary/:date?/:mealTypeId?/:slotId?/:orderId?"
        component={CafeteriaOrderSummary}
        socket={socket}
      />
      <Route
        exact
        path="/addReview/:orderId"
        component={AddReview}
        socket={socket}
      />
      <Route exact path="/myMeals" component={MyMeals} socket={socket} />

      <Route exact path="/profile" component={Profile} socket={socket} />
      <Route
        exact
        path="/paymentManagement"
        component={EmployeePaymentManagement}
        socket={socket}
      />
      <Route
        exact
        path="/successfullySavedPaymentMethod"
        component={SuccessfullySavedPaymentMethod}
        socket={socket}
      />
      <Route exact path="/credit" component={Wallet} socket={socket} />
      <Route
        exact
        path="/terms-condition"
        component={TermsCondition}
        socket={socket}
      />
      <Route
        exact
        path="/invite"
        component={invitationManagement}
        socket={socket}
      />
      <Route
        exact
        path="/privacy-policy"
        component={PrivacyPolicy}
        socket={socket}
      />
      <Route exact path="/contractorEmployee" component={ContractorEmployee} />
      <Route exact path="/guestUsers" component={GuestUsers} />
      <Route
        exact
        path="/reservationManagement"
        component={ReservationManagement}
        socket={socket}
      />
      <Route
        exact
        path="/attendance"
        component={AttendancePage}
        socket={socket}
      />
      <Route
        exact
        path="/reservationByUser"
        component={ReservationByUser}
        socket={socket}
      />
      <Route
        exact
        path="/reservationByMeal"
        component={ReservationByMeal}
        socket={socket}
      />
      {/* <Route
        exact
        path="/allReviews/:date?/:mealTypeId"
        component={AllReviews}
      /> */}
      {/* <Route
        exact
        path="/reviewDetails/:orderId/:dishId"
        component={ReviewsDetails}
      /> */}
      <Route exact path="/settings" component={Settings} socket={socket} />
      <Redirect to={{ pathname: '/lobby' }} socket={socket} />
    </Switch>
  );
};

const KitchenManagerRoutes = ({ user, data }) => {
  return (
    <Switch>
      <Route
        exact
        path="/lobby"
        component={LobbyKitchenManager}
        socket={socket}
      />
      <Route
        exact
        path="/controlPanel"
        component={ControlPanel}
        socket={socket}
      />
      <Route
        exact
        path="/mealManagement"
        component={MealManagement}
        socket={socket}
      />
      <Route exact path="/weekView" component={WeekView} socket={socket} />
      <Route exact path="/pastMeals" component={PastMeals} />
      <Route
        exact
        path="/dishManagement/:TagName?"
        component={DishManagement}
      />
      <Route
        exact
        path="/paymentManagement"
        component={PaymentManagement}
        socket={socket}
      />
      <Route
        exact
        path="/tagManagement"
        component={TagManagement}
        socket={socket}
      />
      <Route
        exact
        path="/addTag/:tagId?"
        component={AddEditTag}
        socket={socket}
      />
      <Route
        exact
        path="/reservationManagement"
        component={ReservationManagement}
        socket={socket}
      />
      <Route
        exact
        path="/attendance"
        component={AttendancePage}
        socket={socket}
      />
      <Route
        exact
        path="/reservationByUser"
        component={ReservationByUser}
        socket={socket}
      />
      <Route
        exact
        path="/reservationByMeal"
        component={ReservationByMeal}
        socket={socket}
      />
      <Route
        exact
        path="/salaryReport"
        component={SalaryReport}
        socket={socket}
      />
      <Route
        exact
        path="/mealReport/:date/:mealTypeId"
        component={MealReport}
        socket={socket}
      />
      <Route
        exact
        path="/menuConstruction/:date?/:mealTypeId?"
        component={MenuConstruction}
        socket={socket}
      />
      <Route
        exact
        path="/printReport"
        component={PrintReports}
        socket={socket}
      />
      <Route
        path="/addEditDish/:dishId?"
        component={AddEditDish}
        socket={socket}
      />
      <Route
        exact
        path="/addDishToMeal/:date/:mealTypeId"
        component={AddDishToMeal}
        socket={socket}
      />
      <Route exact path="/profile" component={Profile} socket={socket} />
      <Route exact path="/settings" component={Settings} socket={socket} />
      <Route
        exact
        path="/terms-condition"
        component={TermsCondition}
        socket={socket}
      />
      <Route
        exact
        path="/privacy-policy"
        component={PrivacyPolicy}
        socket={socket}
      />
      <Route
        exact
        path="/allReviews/:date?/:mealTypeId?"
        component={AllReviews}
        socket={socket}
      />
      <Route
        exact
        path="/reviewDetails/:orderId/:dishId"
        component={ReviewsDetails}
        socket={socket}
      />
      <Route
        exact
        path="/copyPreviousMeal/:date?/:mealTypeId?"
        component={copyPreviousMeal}
        socket={socket}
      />
      <Route
        exact
        path="/addExtrasDishToMeal/:date/:mealTypeId/:mealId"
        component={AddExtrasDishToMeal}
        socket={socket}
      />
      <Redirect to={{ pathname: '/lobby' }} socket={socket} />
      <Route
        exact
        path="/contractorManagement"
        component={ContractorManagement}
        socket={socket}
      />
      <Route
        exact
        path="/reservation-details"
        component={ViewReservationDetails}
      />
      <Route exact path="/logviewer-details" component={LogViewerDetails} />

      <Redirect to={{ pathname: '/lobby' }} />
    </Switch>
  );
};

const SiteManagerRoutes = ({ user, data }) => {
  return (
    <Switch>
      <Route exact path="/lobby" component={LobbySiteManager} socket={socket} />
      <Route
        exact
        path="/controlPanel"
        component={ControlPanel}
        socket={socket}
      />
      <Route
        exact
        path="/mealManagement"
        component={MealManagement}
        socket={socket}
      />
      <Route exact path="/weekView" component={WeekView} socket={socket} />
      <Route
        exact
        path="/userManagement"
        component={UserManagement}
        socket={socket}
      />
      <Route
        exact
        path="/addExtrasDishToMeal/:date/:mealTypeId/:mealId"
        component={AddExtrasDishToMeal}
        socket={socket}
      />
      {/* <Route
        exact
        path="/employeeManagement"
        component={EmployeeManagement}
        socket={socket}
      /> */}
      <Route
        exact
        path="/printReport"
        component={PrintReports}
        socket={socket}
      />
      <Route
        exact
        path="/invite"
        component={invitationManagement}
        socket={socket}
      />
      <Route
        exact
        path="/copyPreviousMeal/:date?/:mealTypeId?"
        component={copyPreviousMeal}
        socket={socket}
      />
      <Route
        exact
        path="/dishManagement/:TagName?"
        component={DishManagement}
      />
      <Route exact path="/pastMeals" component={PastMeals} />
      <Route
        exact
        path="/walletManagement"
        component={WalletManagement}
        socket={socket}
      />
      <Route
        exact
        path="/kitchensManagement"
        component={KitchenManagement}
        socket={socket}
      />
      <Route
        exact
        path="/mealtypes/:kitchenId"
        component={MealTypes}
        socket={socket}
      />
      <Route
        exact
        path="/reservationManagement"
        component={ReservationManagement}
        socket={socket}
      />
      <Route
        exact
        path="/attendance"
        component={AttendancePage}
        socket={socket}
      />
      <Route
        exact
        path="/reservationByUser"
        component={ReservationByUser}
        socket={socket}
      />
      <Route
        exact
        path="/reservationByMeal"
        component={ReservationByMeal}
        socket={socket}
      />
      <Route
        exact
        path="/salaryReport"
        component={SalaryReport}
        socket={socket}
      />
      <Route
        exact
        path="/contractorManagement"
        component={ContractorManagement}
        socket={socket}
      />
      <Route
        exact
        path="/tagManagement"
        component={TagManagement}
        socket={socket}
      />
      <Route
        exact
        path="/addTag/:tagId?"
        component={AddEditTag}
        socket={socket}
      />
      <Route
        exact
        path="/notifications"
        component={Notifications}
        socket={socket}
      />
      <Route
        exact
        path="/mealReport/:date/:mealTypeId"
        component={MealReport}
      />
      <Route
        path="/addEditDish/:dishId?"
        component={AddEditDish}
        socket={socket}
      />
      <Route
        exact
        path="/addDishToMeal/:date/:mealTypeId"
        component={AddDishToMeal}
        socket={socket}
      />
      <Route
        exact
        path="/menuConstruction/:date?/:mealTypeId?"
        component={MenuConstruction}
        socket={socket}
      />
      <Route exact path="/profile" component={Profile} socket={socket} />
      {/* <Route exact path="/security" component={Security} socket={socket} /> */}
      <Route
        exact
        path="/terms-condition"
        component={TermsCondition}
        socket={socket}
      />
      <Route
        exact
        path="/privacy-policy"
        component={PrivacyPolicy}
        socket={socket}
      />
      <Route
        exact
        path="/allReviews/:date?/:mealTypeId?"
        component={AllReviews}
        socket={socket}
      />
      <Route
        exact
        path="/reviewDetails/:orderId/:dishId"
        component={ReviewsDetails}
        socket={socket}
      />
      <Route exact path="/credit" component={Wallet} socket={socket} />
      <Route exact path="/settings" component={Settings} socket={socket} />
      <Route exact path="/user-details" component={ViewDetails} />
      <Route exact path="/employee-details" component={ViewEmployeeDetails} />
      <Route exact path="/contract-details" component={ContractDetails} />
      <Route exact path="/logviewer-details" component={LogViewerDetails} />
      <Redirect to={{ pathname: '/lobby' }} />
    </Switch>
  );
};
const AdminRoutes = ({ user, data }) => {
  return (
    <Switch>
      <Route exact path="/lobby" component={LobbySiteManager} socket={socket} />
      <Route
        exact
        path="/controlPanel"
        component={ControlPanel}
        socket={socket}
      />
      <Route
        exact
        path="/mealtypes/:kitchenId"
        component={MealTypes}
        socket={socket}
      />
      <Route
        exact
        path="/mealManagement"
        component={MealManagement}
        socket={socket}
      />
      <Route
        exact
        path="/reservationForAnotherUser"
        component={ReservationForAnotherUser}
        socket={socket}
      />
      <Route exact path="/weekView" component={WeekView} socket={socket} />
      <Route
        exact
        path="/userManagement"
        component={UserManagement}
        socket={socket}
      />
      {/* <Route
        exact
        path="/employeeManagement"
        component={EmployeeManagement}
        socket={socket}
      /> */}
      <Route
        exact
        path="/walletManagement"
        component={WalletManagement}
        socket={socket}
      />
      <Route
        exact
        path="/kitchensManagement"
        component={KitchenManagement}
        socket={socket}
      />
      <Route
        exact
        path="/tagManagement"
        component={TagManagement}
        socket={socket}
      />
      <Route
        exact
        path="/addTag/:tagId?"
        component={AddEditTag}
        socket={socket}
      />
      <Route
        exact
        path="/mealReport/:date/:mealTypeId"
        component={MealReport}
        socket={socket}
      />
      <Route exact path="/security" component={Security} socket={socket} />
      {/*<Route
        exact
        path="/mealManagement"
        component={MealManagement}
        socket={socket}
    />*/}
      <Route
        exact
        path="/dishManagement/:TagName?"
        component={DishManagement}
        socket={socket}
      />
      <Route
        exact
        path="/paymentManagement"
        component={PaymentManagement}
        socket={socket}
      />
      <Route
        exact
        path="/tagManagement"
        component={TagManagement}
        socket={socket}
      />
      <Route
        exact
        path="/addTag/:tagId?"
        component={AddEditTag}
        socket={socket}
      />
      <Route exact path="/logViewer" component={LogViewer} socket={socket} />
      <Route
        exact
        path="/notifications"
        component={Notifications}
        socket={socket}
      />
      {/*<Route exact path="/audit" component={auditManager} socket={socket} />*/}
      <Route
        exact
        path="/factory"
        component={factoryManagement}
        socket={socket}
      />
      <Route
        exact
        path="/invite"
        component={invitationManagement}
        socket={socket}
      />
      <Route
        exact
        path="/reservationManagement"
        component={ReservationManagement}
        socket={socket}
      />
      <Route
        exact
        path="/attendance"
        component={AttendancePage}
        socket={socket}
      />
      <Route
        exact
        path="/reservationByUser"
        component={ReservationByUser}
        socket={socket}
      />
      <Route
        exact
        path="/reservationByMeal"
        component={ReservationByMeal}
        socket={socket}
      />
      <Route
        exact
        path="/salaryReport"
        component={SalaryReport}
        socket={socket}
      />
      <Route
        exact
        path="/mealReport/:date/:mealTypeId"
        component={MealReport}
        socket={socket}
      />
      <Route
        exact
        path="/menuConstruction/:date?/:mealTypeId?"
        component={MenuConstruction}
        socket={socket}
      />
      <Route
        path="/addEditDish/:dishId?"
        component={AddEditDish}
        socket={socket}
      />
      <Route
        exact
        path="/addDishToMeal/:date/:mealTypeId"
        component={AddDishToMeal}
        socket={socket}
      />
      <Route
        exact
        path="/allReviews/:date?/:mealTypeId?"
        component={AllReviews}
        socket={socket}
      />
      <Route
        exact
        path="/reviewDetails/:orderId/:dishId"
        component={ReviewsDetails}
        socket={socket}
      />
      <Route exact path="/profile" component={Profile} socket={socket} />
      <Route
        exact
        path="/terms-condition"
        component={TermsCondition}
        socket={socket}
      />
      <Route
        exact
        path="/privacy-policy"
        component={PrivacyPolicy}
        socket={socket}
      />
      <Route
        exact
        path="/printReport"
        component={PrintReports}
        socket={socket}
      />
      <Route
        exact
        path="/copyPreviousMeal/:date?/:mealTypeId?"
        component={copyPreviousMeal}
        socket={socket}
      />
      <Route
        exact
        path="/addExtrasDishToMeal/:date/:mealTypeId/:mealId"
        component={AddExtrasDishToMeal}
        socket={socket}
      />
      <Route exact path="/pastMeals" component={PastMeals} />
      <Route exact path="/mealtypes" component={MealTypes} />
      <Route exact path="/credit" component={Wallet} socket={socket} />
      <Route
        exact
        path="/contractorManagement"
        component={ContractorManagement}
      />
      <Route exact path="/contract-details" component={ContractDetails} />
      <Route exact path="/settings" component={Settings} socket={socket} />
      <Route exact path="/user-details" component={ViewDetails} />
      <Route exact path="/employee-details" component={ViewEmployeeDetails} />
      <Route
        exact
        path="/reservation-details"
        component={ViewReservationDetails}
      />
      <Route exact path="/logviewer-details" component={LogViewerDetails} />
      <Route
        exact
        path="/kitchen-devices"
        component={kitchenDeviceManagement}
      />
      <Route exact path="/languages" component={languageManagement} />
      <Redirect to={{ pathname: '/lobby' }} />
    </Switch>
  );
};
