import React, { useEffect, useState } from 'react';
import MuiDialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Box } from '@material-ui/core';
import http from '../../services/http';
import { Dialog } from '../../components/Dialogs/SwalDialogs';
import { useTranslation } from 'react-i18next';
import { useFetchingLoader } from '../../store/app/actions';
import { connect } from 'react-redux';

const UpdatePaymentStatus = ({
  changePaymentStatus,
  changedPaymentParams,
  setChangedPaymentParams,
  setChangePaymentStatus,
  useFetchingLoader,
  setRefresh,
}) => {
  const [paymentType, setPaymentType] = useState('deal_id');
  const handleSubmit = async (params) => {
    console.log('params', params);
    try {
      await useFetchingLoader(
        async () =>
          await http.put(
            `orders/${params.orderID}/changePaymentStatus?lang=en`,
            params
          )
      );
      setChangePaymentStatus(false);
      Dialog({
        title: t('paymentDetailsWasUpdated'),
        type: 'success',
        buttons: {
          showCancelButton: false,
          confirmButtonText: t('confirm'),
        },
        onConfirm: () => {
          setRefresh((prev) => !prev);
        },
      });
    } catch (err) {
      console.log('err', err);
      Dialog({
        title: t('somethingWentWrong'),
        type: 'error',
        onConfirm: () => {
          setRefresh((prev) => !prev);
        },
        buttons: {
          showCancelButton: false,
          confirmButtonText: t('confirm'),
        },
      });
    }
  };
  const { t, i18n } = useTranslation();
  return (
    <MuiDialog
      open={changePaymentStatus}
      onClose={() => {
        setChangePaymentStatus(false);
        setChangedPaymentParams({});
      }}
      disableEnforceFocus
    >
      <DialogTitle>{t('changePaymentStatus')}</DialogTitle>
      <DialogContent className="d-flex gap-3 flex-column m-0 text-start">
        <DialogContentText>
          {`${t('changePaymentStatusMess')} ${
            changedPaymentParams.action == 'payment'
              ? t('fromPendingToPaid')
              : t('fromPaidToCancel')
          }?`}
        </DialogContentText>
        {changedPaymentParams.action == 'payment' ? (
          <FormControl className="d-flex gap-3 flex-column m-0 text-start">
            <FormLabel
              id="demo-radio-buttons-group-label"
              className="text-color-for-payment-change"
            >
              {t('howOrderWasPaid')}
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
              value={paymentType}
              onChange={(event) => setPaymentType(event.target.value)}
            >
              <FormControlLabel
                value="deal_id"
                control={
                  <Radio className="radio-button-color-for-payment-change" />
                }
                label={t('orderPaidBySite')}
              />
              <FormControlLabel
                value="orderID"
                control={
                  <Radio className="radio-button-color-for-payment-change" />
                }
                label={t('orderPaidByOtherMethods')}
              />
            </RadioGroup>
          </FormControl>
        ) : (
          ''
        )}
        {paymentType === 'deal_id' &&
        changedPaymentParams.action == 'payment' ? (
          <Box className="d-flex gap-2 flex-column m-0 text-start">
            <TextField
              label={t('paymentOrderId')}
              type="number"
              fullWidth
              required
              variant="standard"
              onChange={(event) =>
                setChangedPaymentParams((param) => ({
                  ...param,
                  deal_id: event.target.value,
                }))
              }
            />
            <TextField
              label={t('message')}
              fullWidth
              required
              variant="standard"
              onChange={(event) =>
                setChangedPaymentParams((param) => ({
                  ...param,
                  message: event.target.value,
                }))
              }
            />
          </Box>
        ) : (
          ''
        )}
        {paymentType === 'orderID' &&
        changedPaymentParams.action == 'payment' ? (
          <Box className="d-flex gap-2 flex-column m-0 text-start">
            <TextField
              label={t('paymentOrderId')}
              type="number"
              fullWidth
              required
              variant="standard"
              onChange={(event) =>
                setChangedPaymentParams((param) => ({
                  ...param,
                  orderId: event.target.value,
                }))
              }
            />
            <TextField
              label={t('message')}
              fullWidth
              required
              variant="standard"
              onChange={(event) =>
                setChangedPaymentParams((param) => ({
                  ...param,
                  message: event.target.value,
                }))
              }
            />
          </Box>
        ) : (
          ''
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setChangePaymentStatus(false);
            setChangedPaymentParams({});
          }}
          variant="contained"
          className="success-btn-payment-cancel"
        >
          {t('close')}
        </Button>
        <Button
          onClick={() =>
            (changedPaymentParams.deal_id &&
              changedPaymentParams.userId &&
              changedPaymentParams.message) ||
            (changedPaymentParams.orderId && changedPaymentParams.message) ||
            changedPaymentParams.action !== 'payment'
              ? handleSubmit(changedPaymentParams)
              : Dialog({
                  title: t('fillRequiredFields'),
                  type: 'error',
                  buttons: {
                    showCancelButton: false,
                    confirmButtonText: t('confirm'),
                  },
                })
          }
          variant="contained"
          type="submit"
          className="success-btn-payment-change"
        >
          {t('ok')}
        </Button>
      </DialogActions>
    </MuiDialog>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  useFetchingLoader,
};

// export default ReservationManagement;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdatePaymentStatus);
